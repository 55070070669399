.summary {
  padding: 20px;

  line-height: 1.3;
  color: rgb(0 0 0 / 60%);

  background-color: #f6f4f0;
}

* + .summary {
  margin-top: 16px;
}

@media (--tablet) {
  .summary {
    color: rgb(0 0 0 / 75%);
  }
}

.summary__title {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--black);
}

.summary > ul {
  list-style: none;
}

.summary > * + ul {
  margin-top: 12px;
}

.summary > ul > * + li,
.summary > ul > li > ul > * + li {
  margin-top: 8px;
}

.summary > ul > li > ul {
  padding-left: 24px;
  list-style: none;
}

.summary > ul > li > * + ul {
  margin-top: 12px;
}

.summary__heading {
  color: inherit;
  text-decoration: none;
}

a.summary__heading:hover {
  text-decoration: underline;
}
