.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-menu);

  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);

  background-color: var(--black);

  will-change: clip-path;
  display: none;
}

@media (--mobile) {
  .menu {
    display: block;
  }
}

.menu.menu--open {
  transform: translateX(0);
}

.menu__header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding-top: 108px;
  margin-right: auto;
  margin-left: auto;

  color: var(--white);
  font-size: 17px;
}

.header--dark .menu__header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  margin-right: auto;
  margin-left: auto;

  color: var(--black);
  font-size: 17px;
}

.menu__header__title {
  font-family: var(--font-family-heading);
  letter-spacing: 0.08em;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  border-bottom: 2px solid white;
  padding-bottom: 5px;
}

.menu__burger {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-left: auto;

  background: none;
  border: none;
  cursor: pointer;
}
.menu__burger.menu__burger--touch-down {
  transform: scale(0.85);

  transition-duration: 180ms;
}
.js-focus-visible .menu__burger:focus:not(.focus-visible) {
  outline: none;
}

.menu__burger div {
  position: relative;

  margin-top: -4px;
}

.menu__burger div,
.menu__burger div::before,
.menu__burger div::after {
  display: block;
  width: 26px;
  height: 2px;

  border-radius: 2px;
  transform-origin: center center;
}
.menu__burger div::before,
.menu__burger div::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--white);

  content: '';
}
.menu__burger div::before {
  transform: rotate(-45deg);
}
.menu__burger div::after {
  transform: rotate(45deg);
}

.menu__lists {
  margin-top: 97px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.menu__lists ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  list-style-type: none;
}

.menu__lists ul > li > hr {
  height: 20px;

  border: none;
}

.menu__lists ul:not(.menu__ctas) > li > a {
  display: block;

  text-decoration: none;
  color: var(--white);
  font-family: var(--font-family-heading);
  letter-spacing: 0.08em;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;

  transition-duration: 120ms;
  transition-property: color;
  text-align: center;
  text-transform: uppercase;
  margin-top: 50px;
}
.menu .menu__ctas {
  list-style: none;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-height: 500px) {
  .menu__lists ul > li > a {
    padding-top: 4px;
    padding-bottom: 4px;

    font-size: 24px;
  }
}

.menu__lists ul > li > a:hover {
  color: var(--white);
  text-decoration: underline;
}

.menu__locales > li > a {
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  text-transform: uppercase;
  color: rgb(177, 177, 177) !important;
}
* + .menu__locales {
  margin-top: 16px;
}

* + .menu__ctas {
  margin-top: 20px;
}

.menu__logo {
  position: relative;

  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  text-align: center;

  cursor: pointer;
}

.menu__logo img {
  --size: 60px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}
@media (--tablet) {
  .menu__logo img {
    --size: 50px;
  }
}
