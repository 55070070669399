.product-item {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: var(--min-height);

  text-align: left;

  transition: 300ms background-color;
}

.product-item--loading {
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-100);
}

.product-item__image-container {
  position: relative;

  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.product-item__image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  user-select: none;
  transition: transform 0.3s ease-in-out;
}

.product-item a:hover .product-item__image-container img {
  transform: scale(1.07);
}

.product-item__image--active {
  display: block;
}

.product-item__image:not(.product-item__image--active) {
  display: none;
}

.product-item__title {
  margin-top: 16px;

  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-family: var(--font-family-heading);
  color: var(--black);
}

@media (--mobile) {
  .product-item__title {
    font-size: 16px;
    letter-spacing: 0.32px;
  }
}

.product-item__title a {
  color: black;
  text-decoration: none;
  text-decoration-line: underline;
  text-decoration-thickness: 0.09em;
  text-underline-offset: 0.15em;
  text-decoration-color: rgba(var(--black-rgb), 0);

  transition: text-decoration-color 100ms;
}
.product-item__title a:hover {
  text-decoration-color: rgba(var(--black-rgb), 1);
}
.product-item__title a::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.product-item__weight p {
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: var(--black);
  font-family: var(--font-family-heading);
  opacity: 0.7;
}
* + .product-item__weight {
  margin-top: 8px;
}

.product-item__options {
  position: relative;
  z-index: 1;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
* + .product-item__options {
  margin-top: 16px;
}

.product-item__options__item input[type='radio'] {
  display: none;
}
.product-item__options__item {
  display: flex;
  flex-direction: row;
}
.product-item__options__item label {
  padding: 7px 14px;

  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.12px;

  cursor: pointer;
  border-radius: 9999px;
  border: 1px solid rgba(var(--black-rgb), 0.2);
  transition:
    200ms border-color,
    200ms background-color;
}
.product-item__options__item label:hover {
  background-color: rgba(var(--black-rgb), 0.05);
}
.product-item__options__item label:active {
  background-color: transparent;
}
.product-item__options__item label:hover,
.product-item__options__item input:checked + label {
  border-color: var(--black);
}

.product-item__price {
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: 0.02em;
  font-family: var(--font-family-heading);
  color: var(--black);
  text-transform: uppercase;
}
* + .product-item__price {
  margin-top: 16px;
}

.product-item__add-to-cart {
  position: relative;
  z-index: 1;

  width: 100%;
  margin-top: auto;
  padding-top: 20px;
}
