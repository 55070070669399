.all-recipes {
  --card-mobile-width: 185px;
  --card-mobile-gap: 32px;
  --nb-card: 3;
  --icon-size: 26px;
}

@media (--mobile) {
  .all-recipes {
    max-width: 420px;
    margin-right: auto;
    margin-left: auto;
  }
}

.subject + .all-recipes {
  margin-top: 64px;
}

@media (--mobile) {
  .subject + .all-recipes {
    margin-top: 40px;
  }
}

.all-recipes__container {
  display: grid;
  grid-template-columns: repeat(var(--nb-card), 1fr);
  grid-gap: 24px;
}

@media (--tablet) {
  .all-recipes__container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--mobile) {
  .all-recipes__container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.all-recipes__loading {
  display: flex;
  justify-content: center;
  margin: 40px;
}
