* + .product-discover {
  margin-top: 64px;
}
@media (--mobile) {
  * + .product-discover {
    margin-top: 32px;
  }
}

.product-rating + .product-discover {
  margin-top: 0;
}

.product-rating + .product-discover hr {
  border: none;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

@media (--mobile) {
  .product-discover hr {
    display: none;
  }
}

.product-discover {
  --card-mobile-width: 185px;
  --card-mobile-gap: 24px;
  --nb-card: 3;
}

.product-discover h2 {
  color: var(--black);
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
}

@media (--mobile) {
  .product-discover h2 {
    font-size: 24px;
    text-align: center;
    letter-spacing: 2.4px;
  }
}

* + .product-discover__content {
  margin-top: 40px;
}

.product-discover__content {
  display: grid;
  grid-template-columns: repeat(var(--nb-card), 1fr);
  grid-gap: 56px;
}

.product-discover__content .product-item__image {
  height: 176px;
}

* + .product-discover__content-container {
  margin-top: 40px;
}

@media (--mobile) {
  .product-discover__content-container {
    position: relative;
    left: 50%;
    right: 50%;

    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-left: var(--page-left-padding);
    padding-right: var(--page-right-padding);

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  .product-discover__content-container::-webkit-scrollbar {
    display: none;
  }

  .product-discover__content {
    grid-gap: var(--card-mobile-gap);
    padding-right: 200px;
    width: calc(
      var(--card-mobile-width) * var(--nb-card) + var(--page-right-padding) +
        var(--card-mobile-gap) * (var(--nb-card) - 1)
    );
  }

  .product-discover__content .product-item {
    width: var(--card-mobile-width);
  }
}
