* + .recipe-discover {
  margin-top: 60px;
}

.recipe-discover {
  --card-mobile-width: 185px;
  --card-mobile-gap: 32px;
  --nb-card: 3;
  --icon-size: 26px;
}

.recipe-discover h2 {
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
  color: var(--black);
  text-align: center;
}

@media (--mobile) {
  .recipe-discover h2 {
    font-size: 24px;
    letter-spacing: 2.4px;
    text-align: left;
  }
}

* + .recipe-discover__content {
  margin-top: 60px;
}

.recipe-discover__content {
  display: grid;
  grid-template-columns: repeat(var(--nb-card), 1fr);
  grid-gap: 56px;
}

@media (--mobile) {
  .recipe-discover__content {
    display: block;
  }

  * + .recipe-card {
    margin-top: 40px;
  }
}

.recipe-card {
  position: relative;
}

.recipe-card__image {
  width: 100%;
  height: 293px;
}

.recipe-card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recipe-card__bottom {
  padding: 16px 24px 24px;

  background-color: #f6f4f0;
}

.recipe-card__bottom h3 {
  font-family: var(--font-family-heading);
  font-size: 18px;
  font-weight: 700;
  line-height: 134.2%;
  color: black;
}

* + .recipe-card__bottom__content {
  margin-top: 33px;
}

.recipe-card__bottom__content {
  display: flex;
  justify-content: space-between;
}

.recipe-card__bottom__content__left {
  display: flex;
}

.recipe-card__bottom__content__left * + p {
  margin-left: 8px;
}

.recipe-card__bottom__content__left p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 134.2%;
  color: #776c57;
  transform: translateY(6px);
}

.recipe-card__bottom__content__left img {
  width: var(--icon-size);
  height: var(--icon-size);
  padding-right: 8px;

  border-right: 1px solid #776c57;
}

.recipe-card__bottom__content a {
  margin-top: 5px;

  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 134.2%;
  color: black;
  text-decoration: none;
}

.recipe-card__bottom__content a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.recipe-card__bottom__content a svg {
  height: 10px;
  margin-left: 8px;
}

.recipe-card__tag {
  position: absolute;
  top: 16px;
  right: 23px;

  padding: 5px 16px;

  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;

  color: #776c57;

  border-radius: 14.5px;
  background: #f6f4f0;
}
