.history-header-pic {
  height: calc(301px + var(--header-height));
  width: 100%;
}

.history-header-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}
