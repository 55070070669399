.blog-header {
  padding-top: 200px;
  padding-bottom: 64px;

  color: var(--black);

  background-color: #f6f4f0;
}

@media (--tablet) {
  .blog-header {
    padding-top: 128px;
    padding-bottom: 32px;
  }
}

@media (--mobile) {
  .blog-header {
    padding-top: 92px;
    padding-bottom: 32px;
  }
}

.blog-header__header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.blog-header__date {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4;
  color: rgb(0 0 0 / 70%);
}

.blog-header__title {
  max-width: 36ch;

  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  color: #2f250f;
}

* + .blog-header__title {
  margin-top: 16px;
}

@media (--tablet) {
  .blog-header__title {
    font-size: 32px;
  }
}

@media (--mobile) {
  .blog-header__title {
    font-size: 24px;
  }

  .blog-header__title br {
    display: none;
  }
}

.blog-header__introduction {
  max-width: 55ch;

  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(0 0 0 / 70%);
}

* + .blog-header__introduction {
  margin-top: 16px;
}

@media (--tablet) {
  .blog-header__introduction {
    font-size: 17px;
  }
}

@media (--mobile) {
  .blog-header__introduction {
    font-size: 16px;
  }
}

.blog-header__introduction > * + p {
  margin-top: 12px;
}

.blog-header__footer {
  display: flex;
  align-items: center;
  gap: 8px;
}

* + .blog-header__footer {
  margin-top: 12px;
}

.blog-header__authors {
  display: flex;
  flex-shrink: 0;
}

.blog-header__authors img {
  --size: 32px;

  width: var(--size);
  height: var(--size);

  border: 1px solid var(--white);
  border-radius: 50%;
  object-fit: cover;
}

.blog-header__authors img + img {
  margin-left: calc(-0.25 * var(--size));
}
