.blog-body__aside__sticky {
  position: sticky;
  top: var(--header-height);

  flex-shrink: 0;
}

.blog-body__aside__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: #2f250f;
}

* + .blog-body__aside__title {
  margin-top: 24px;
}

@media (--mobile) {
  * + .blog-body__aside__title {
    margin-top: 16px;
  }
}

.blog-body__authors {
  list-style: none;
}

* + .blog-body__authors {
  margin-top: 12px;
}

.blog-body__author {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

* + .blog-body__author {
  margin-top: 12px;
}

.blog-body__author__image {
  --size: 40px;

  width: var(--size);
  height: var(--size);

  border: 1px solid var(--white);
  border-radius: 50%;
  object-fit: cover;
}

.blog-body__author__name {
  display: block;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--black);
}

.blog-body__author__name a {
  color: inherit;
  text-decoration: none;
}

.blog-body__author__name a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.blog-body__author__name a:hover {
  text-decoration: underline;
}

.blog-body__author__role {
  display: block;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: rgb(var(--black-rgb) 0.7);
}
