.history-step {
  position: relative;

  padding: 93px 0 79px 0;
}
@media (--mobile) {
  .history-step {
    padding: 32px 0 32px 0;
  }
}

.history-step::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: #bbb6ab;

  z-index: 1;

  @media (--mobile) {
    display: none;
  }
}
