.blog-body__content {
  --gap: 1.5rem;

  flex-grow: 0;
  min-width: 0;

  font-size: 16px;
  line-height: 1.6;

  color: rgb(0 0 0 / 80%);
}

@media (--mobile) {
  .blog-body__content {
    --gap: 1.24rem;
  }
}

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

.blog-body__content > :--heading {
  --js-scroll-offset-top: 64px;

  font-weight: 600;
  line-height: 1.35;
  color: #2f250f;
}

.blog-body__content > * + :--heading {
  margin-top: 2.5rem;
}

.blog-body__content > * + h2 {
  margin-top: 3.5rem;
}

@media (--mobile) {
  .blog-body__content > * + :--heading {
    margin-top: 2rem;
  }

  .blog-body__content > * + h2 {
    margin-top: 2.5rem;
  }
}

.blog-body__content > :--heading a {
  color: inherit;
  text-decoration: none;
}

.blog-body__content > :--heading a:hover {
  text-decoration: underline;
}

.blog-body__content > h2 {
  font-size: 28px;
}

.blog-body__content > h3 {
  font-size: 23px;
}

.blog-body__content > h4 {
  font-size: 20px;
}

.blog-body__content > h5 {
  font-size: 18px;
}

.blog-body__content > h6 {
  font-size: 16px;
}

@media (--tablet) {
  .blog-body__content > h2 {
    font-size: 26px;
  }

  .blog-body__content > h3 {
    font-size: 21px;
  }

  .blog-body__content > h4 {
    font-size: 18px;
  }

  .blog-body__content > h5 {
    font-size: 16px;
  }

  .blog-body__content > h6 {
    font-size: 15px;
  }
}

@media (--mobile) {
  .blog-body__content > h2 {
    font-size: 24px;
  }

  .blog-body__content > h3 {
    font-size: 19px;
  }

  .blog-body__content > h4 {
    font-size: 17px;
  }

  .blog-body__content > h5 {
    font-size: 16px;
  }

  .blog-body__content > h6 {
    font-size: 15px;
  }
}

.blog-body__content > * + p {
  margin-top: var(--gap);
}

.blog-body__content > * a {
  color: #6c572e;
  text-decoration: none;
}

.blog-body__content > * a:hover {
  text-decoration: underline;
}

.blog-body__content > ul,
.blog-body__content > ol {
  padding-left: 24px;
}

.blog-body__content > * + ul,
.blog-body__content > * + ol {
  margin-top: var(--gap);
}

.blog-body__content > ul > * + li,
.blog-body__content > ol > * + li {
  margin-top: 8px;
}

.blog-body__content > * > img {
  display: block;
  max-width: 100%;
  height: auto;
}
