* + .recipe {
  margin-top: 120px;
}
@media (--tablet) {
  * + .recipe {
    margin-top: 64px;
  }
}
@media (--mobile) {
  * + .recipe {
    margin-top: 32px;
  }
}

.recipe__title {
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
@media (--mobile) {
  .recipe__title {
    text-align: center;
    font-size: 24px;
    letter-spacing: 2.4px;
  }
}
.recipe__list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 56px;
}

@media (--mobile) {
  .recipe__list {
    display: block;
  }

  * + .recipe__item {
    margin-top: 20px;
  }
}

* + .recipe__list {
  margin-top: 40px;
}
@media (--mobile) {
  * + .recipe__list {
    margin-top: 20px;
  }
}

.recipe__item {
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  flex: 1 0 0;
  background-color: #f6f4f0;
  border: 2px solid #f6f4f0;
  text-align: center;
}

.recipe__item__image {
  width: 78px;
  height: 68px;
  margin-left: auto;
  margin-right: auto;
}

.recipe__item__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.recipe__item__title {
  color: #776c57;
  font-family: var(--font-family-heading);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.recipe__item__content {
  max-width: 26ch;
  margin-left: auto;
  margin-right: auto;
  color: #776c57;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.96px;
}
