.hero {
  position: relative;
}
@media (--mobile) {
  .hero {
    min-height: 450px;
  }
}

.hero__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero__background__gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: linear-gradient(
    90deg,
    rgba(var(--black-rgb), 0.4) 0%,
    rgba(var(--black-rgb), 0) 100%
  );
}
@media (--mobile) {
  .hero__background__gradient {
    display: none;
  }
}

.hero__background__gradient__mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: none;

  background: linear-gradient(
    180deg,
    rgba(var(--black-rgb), 0.36) 0%,
    rgba(var(--black-rgb), 0) 50%
  );
}
@media (--mobile) {
  .hero__background__gradient__mobile {
    display: block;
  }
}

.hero__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 78%;

  user-select: none;
}

.hero__container {
  --horizontal-padding: 0;

  position: relative;
  z-index: 1;

  padding-top: 250px;
  padding-right: var(--horizontal-padding);
  padding-bottom: 121px;
  padding-left: var(--horizontal-padding);
}
@media (--mobile) {
  .hero__container {
    --horizontal-padding: 8px;

    margin-top: var(--header-height);
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

.hero__title h1 {
  max-width: 16ch;

  font-weight: 700;
  font-size: 42px;
  line-height: 125%;
  letter-spacing: 0.04em;
  font-family: var(--font-family-heading);
  color: var(--white);
  letter-spacing: 2.1px;
}
@media (--mobile) {
  .hero__title h1 {
    max-width: 100%;

    font-size: 28px;
    letter-spacing: 1.28px;
  }
}

.hero__description p {
  max-width: 34ch;

  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--white);
  font-family: var(--font-family);
}
* + .hero__description {
  margin-top: 8px;
}
@media (--mobile) {
  .hero__description p {
    max-width: 100%;

    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--white-rgb), 0.8);
  }
  * + .hero__description {
    margin-top: 12px;
  }
}

.hero__button {
  display: flex;
  align-items: center;
  margin-top: 32px;
}
@media (--mobile) {
  .hero__button {
    margin-top: 12px;
  }
}

@media (--mobile) {
  .hero__button .button {
    --horizontal-padding: 0;
  }

  .hero__button .button:not(.button--disabled):hover {
    --color: var(--black);

    --background-color: transparent;
  }

  .hero__button .button:not(.button--disabled):active {
    --color: var(--black);
    --background-color: transparent;
  }

  .hero__button .button:not(.button--disabled) {
    --background-color: transparent;
  }

  .hero__button .button .button__text {
    color: var(--white);
  }

  .hero__button .button .button__right-icon svg * {
    fill: var(--white);
  }
}
