.footer {
  background-color: var(--black);
  color: var(--white);
  font-size: 14px;
}

.footer__content {
  padding-top: 42px;
  padding-bottom: 42px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(6, 1fr);
}
@media (--tablet) {
  .footer__content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 34px;
  }
}
@media (--mobile) {
  .footer__content {
    grid-gap: 38px;
  }
}

.footer__infos__logo {
  display: block;
  width: 125.52px;
  height: 40px;
}
@media (--mobile) {
  .footer__infos__logo {
    width: 160px;
    height: 80px;
    margin: auto;
  }
}

.footer__infos__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer__social {
  display: flex;
  align-items: center;
  max-width: 115px;
  list-style: none;
}

* + .footer__social {
  margin-top: 16px;
}

.footer__social li {
  --icon-size: 18px;
  width: calc(var(--icon-size) + 20px);
  height: var(--icon-size);
}

.footer__social li img {
  height: var(--icon-size);
  width: var(--icon-size);
}

@media (--mobile) {
  .footer__social li {
    --icon-size: 25px;
  }

  .footer__social li:not(:first-of-type) {
    margin-left: 15px;
  }
}

.footer__social li a {
  height: 100%;
  padding-right: 17.56px;
}

.footer__social li a img {
  height: 100%;
  transition: 200ms transform;
  user-select: none;
}
.footer__social li a:hover img {
  transform: scale(1.15);
}
.footer__social li a:active img {
  transform: scale(1);
  transition: 100ms transform;
}

.footer__column__item:first-of-type {
  grid-column: 1 / 3;
}

@media (--tablet) {
  .footer__column__item:first-of-type {
    grid-column: auto;
  }
}

@media (--mobile) {
  .footer__column__item:first-of-type {
    grid-column: 1 / 3;
    text-align: center;
  }

  .footer__column__item + .footer__column__item {
    margin-top: 16px;
  }
}

.footer__column__item:last-of-type {
  grid-column: 5 / 7;
}

@media (--tablet) {
  .footer__column__item:last-of-type {
    grid-column: auto;
  }
}

@media (--mobile) {
  .footer__column__item:last-of-type {
    grid-column: 1/ 3;
  }
}

.footer__column__item__title h2 {
  font-family: var(--font-family-heading);
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;

  letter-spacing: 0.08em;
  text-transform: uppercase;
}

* + .footer__column__item__content {
  margin-top: 12px;
}

.footer__column__item__content p {
  font-weight: 400;
  font-size: 13px;
  line-height: 125%;
  letter-spacing: 0.04em;

  color: #ffffff;

  opacity: 0.8;
}

@media (--mobile) {
  .footer__column__item__title h2 {
    font-size: 18px;
  }

  .footer__column__item__content p {
    font-size: 16px;
  }

  .footer__column__item__content a {
    font-size: 16px;
  }
}

.footer__column__item__content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer__column__item__content button {
  text-align: left;
}

.footer__column__item__content a,
.footer__column__item__content button {
  --vertical-padding: 4px;

  display: block;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  color: var(--white);
  text-decoration: none;
  opacity: 0.7;
  transition: 200ms opacity;
}
.footer__column__item__content a:hover,
.footer__column__item__content button:hover {
  opacity: 1;
  text-decoration: underline;
}
@media (--mobile) {
  .footer__column__item__content a,
  .footer__column__item__content button {
    --vertical-padding: 6px;
  }
}
