@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-Light.woff2') format('woff2'),
    url('/font-faces/CeraPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-MediumItalic.woff2') format('woff2'),
    url('/font-faces/CeraPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-RegularItalic.woff2') format('woff2'),
    url('/font-faces/CeraPro-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-BlackItalic.woff2') format('woff2'),
    url('/font-faces/CeraPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-Medium.woff2') format('woff2'),
    url('/font-faces/CeraPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-BoldItalic.woff2') format('woff2'),
    url('/font-faces/CeraPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-ThinItalic.woff2') format('woff2'),
    url('/font-faces/CeraPro-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-LightItalic.woff2') format('woff2'),
    url('/font-faces/CeraPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-Black.woff2') format('woff2'),
    url('/font-faces/CeraPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-Bold.woff2') format('woff2'),
    url('/font-faces/CeraPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-Regular.woff2') format('woff2'),
    url('/font-faces/CeraPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url('/font-faces/CeraPro-Thin.woff2') format('woff2'),
    url('/font-faces/CeraPro-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* Montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url('/font-faces/Montserrat-Regular.woff2') format('woff2'),
    url('/font-faces/Montserrat-Regular.woff') format('woff');
}

/* Montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;

  src:
    local('Montserrat Semibold'),
    local('Montserrat-Semibold'),
    url('/font-faces/Montserrat-SemiBold.woff2') format('woff2'),
    url('/font-faces/Montserrat-SemiBold.woff') format('woff');
}
