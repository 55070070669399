* + .recipe-preparation {
  margin-top: 87px;
}

.recipe-preparation .max-width {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  gap: 68px;
}

@media (--mobile) {
  .recipe-preparation .max-width {
    display: block;
  }
}

.recipe-preparation__ingredients__content {
  padding: 48px 40px;

  background-color: #f6f4f0;
}
@media (--mobile) {
  .recipe-preparation__ingredients__content {
    padding: 40px 32px;
  }
}

.recipe-preparation__ingredients__content h2 {
  color: var(--black);
  text-align: center;
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}
@media (--mobile) {
  .recipe-preparation__ingredients__content h2 {
    font-size: 24px;
  }
}

* + .recipe-preparation__ingredients__content__persons {
  margin-top: 16px;
}

.recipe-preparation__ingredients__content__persons {
  display: none;
  text-align: center;

  color: black;
  font-family: var(--font-family);
  font-size: 14px;

  font-weight: 500;
  line-height: 14px;
}

@media (--mobile) {
  .recipe-preparation__ingredients__content__persons {
    display: block;
  }
}

* + .recipe-preparation__ingredients__items {
  margin-top: 28px;
}

.recipe-preparation__ingredients__item {
  display: flex;
  padding: 22px 0;
  gap: 24px;
  justify-content: space-between;
}
@media (--mobile) {
  .recipe-preparation__ingredients__item {
    padding: 16px 0;
  }
}

.recipe-preparation__ingredients__item:last-of-type {
  padding-bottom: 0;
}

* + .recipe-preparation__ingredients__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.recipe-preparation__ingredients__item__text {
  color: black;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.recipe-preparation__ingredients__item__text a {
  font-weight: 600;
  color: #776c57;
}

.recipe-preparation__ingredients__item__quantity {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recipe-preparation__ingredients__item__quantity p {
  color: #776c57;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 600;
  line-height: 14px;
}
@media (--mobile) {
  * + .recipe-preparation__preparation {
    margin-top: 52px;
  }
}

.recipe-preparation__preparation__step--image img {
  width: 100%;
  object-fit: contain;
  height: auto;
}

.recipe-preparation__preparation__top {
  display: flex;
  gap: 20px;
}

.recipe-preparation__preparation__top__line {
  position: relative;
  width: 100%;
}

.recipe-preparation__preparation__top__line hr {
  position: absolute;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  bottom: 27%;
  left: 0;
  right: 0;
}

.recipe-preparation__preparation__top h2 {
  color: var(--black);
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
@media (--mobile) {
  .recipe-preparation__preparation__top h2 {
    font-size: 24px;
  }
}

.recipe-preparation__preparation__step--text {
  display: flex;
  gap: 51px;
}

@media (--mobile) {
  .recipe-preparation__preparation__step--text {
    gap: 32px;
  }
}

.recipe-preparation__preparation__step--text__content__step-index {
  min-width: 32px;
  color: black;
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.84px;
}

.recipe-preparation__preparation__step--text__content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--black);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.48px;
}
.recipe-preparation__preparation__step--text__content * + p {
  margin-top: 16px;
}

.recipe-preparation__preparation__step--text__content a {
  color: #776c57;
  font-weight: 600;
}

* + .recipe-preparation__preparation__steps {
  margin-top: 50px;
}
@media (--mobile) {
  * + .recipe-preparation__preparation__steps {
    margin-top: 40px;
  }
}

* + .recipe-preparation__preparation__step {
  margin-top: 40px;
}
