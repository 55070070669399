.blog-body {
  --color: var(--black);

  padding-top: 64px;
  padding-bottom: 128px;

  color: var(--color);

  background-color: var(--white);
}

@media (--mobile) {
  .blog-body {
    padding-top: 32px;
    padding-bottom: 64px;
  }
}

.blog-body__wrap {
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 64px;
}

@media (--tablet) {
  .blog-body__wrap {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
  }
}
