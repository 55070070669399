.product-hero-slider__pagination {
  position: absolute;
  right: 0;
  bottom: 16px;
  left: 0;

  display: flex;
  justify-content: center;

  pointer-events: none;
  z-index: 1;
}
@media (max-width: 600px) {
  .product-hero-slider__pagination {
    bottom: 12px;
  }
}

.product-hero-slider__pagination > div {
  width: 8px;
  height: 8px;

  background-color: var(--white);
  border-radius: 50%;

  transition:
    background-color 300ms,
    transform 300ms;
}
@media (max-width: 600px) {
  .product-hero-slider__pagination > div {
    width: 8px;
    height: 8px;
  }
}

.product-hero-slider__pagination > div.--active {
  background-color: var(--secondary-800);
  box-shadow: 0 0 0 2px rgba(var(--white-rgb), 0.85);
  transform: scale(1.3);
}

.product-hero-slider__pagination > div + div {
  margin-left: 10px;
}
@media (max-width: 600px) {
  .product-hero-slider__pagination > div + div {
    margin-left: 8px;
  }
}
