@custom-media --tablet (max-width: 1024px);
@custom-media --mobile (max-width: 768px);
@custom-media --header-breakpoint (max-width: 768px);

:root {
  /* Sizes */
  --page-max-width: 1020px;

  --page-horizontal-padding: 24px;
  --page-left-padding: calc(var(--page-horizontal-padding));
  --page-left-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-left)
  );
  --page-right-padding: calc(var(--page-horizontal-padding));
  --page-right-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-right)
  );

  --header-height: calc(var(--header-sup-height) + var(--header-main-height));
  --header-sup-height: 32px;
  --header-main-height: 82px;

  /* Z-indexes */
  --z-index-header: 10;
  --z-index-cart-modal: 20;
  --z-index-cart-modal-overlay: 9;
  --z-index-menu: 30;

  /* Font families */
  --font-family: 'Montserrat', sans-serif;
  --font-family-heading: 'CeraPro', sans-serif;

  /* Font sizes */
  --p-size: 16px;
  --title-size: 32px;

  --p-line-height: 1.3;
  --title-line-height: 1.25;

  /* Colors */
  --white: #ffffff;
  --white-rgb: 255, 255, 255;

  --black: #000000;
  --black-rgb: 0, 0, 0;

  --secondary-100: #f6f4f0;
  --secondary-400: #e8cc82;
  --secondary-500: #d8bd73;
  --secondary-800: #534622;

  --orange-500: #dfa244;
  --yellow-500: #e8d64e;
  --violet-500: #5868ae;
  --red-500: #bf4440;
  --purple-500: #af4e8d;
}

@media (--tablet) {
  :root {
    --header-main-height: 72px;
  }
}

@media (--header-breakpoint) {
  :root {
    --header-sup-height: 0px;
    --header-main-height: 72px;
  }
}
