.header {
  --header-padding-top: 10px;
  --gap: 24px;
  --logo-width: 160px;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-header);

  height: var(--header-height);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  text-align: center;

  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2.5px);
  transition: background-color 0.3s ease-in-out;
}
@media (--tablet) {
  .header {
    --gap: 14px;
    --logo-width: 80px;
  }
}
@media (--header-breakpoint) {
  .header {
    --header-padding-top: 12px;
  }
}
@media (--mobile) {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    background-color: var(--white);
    backdrop-filter: none;
  }
}

.header--scrolled {
  background-color: var(--white);
  border-bottom: 1px solid #dedede;
  box-shadow: 0px 0px 12px 0px rgba(var(--black-rgb), 0.05);
}

.header--scrolled .header__gradient {
  opacity: 1;
  transform: translateY(0);
}
@media (--header-breakpoint) {
  .header__gradient {
    height: 100px;
  }
}

.header__sup-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header-sup-height);
  padding: 8px 10px;

  background-color: var(--black);
  text-align: center;
}

.header__sup-header span {
  font-family: var(--font-family-heading);
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.08em;

  color: var(--white);
}

@media (--mobile) {
  .header .header__logo img {
    --size: 45px;
  }

  .header {
    position: fixed;
  }
  .header .header__sup-header {
    display: none;
  }

  .header .header__logo__light {
    display: none;
  }

  .header .header__logo__dark {
    display: none;
  }

  .header .header__logo__mobile__scrolled {
    display: block;
  }
}

.header__grid {
  display: grid;
  align-items: center;
  grid-template-columns: 32px 1fr var(--logo-width) 1fr 32px;
}
@media (--header-breakpoint) {
  .header__grid {
    grid-template-columns: 64px 1fr 32px 32px;
    padding-top: 12px;
  }
}

.header__logo {
  width: 100%;
  padding-top: var(--header-padding-top);
  margin-bottom: var(--header-padding-top);
}

@media (--header-breakpoint) {
  .header__logo {
    padding: 0;
    margin: 0;
  }
}

.header__logo img {
  --size: 62px;

  width: 100%;
  height: var(--size);
  object-fit: contain;
  margin: auto;
}

.header__logo__light {
  display: block;
}

.header__logo__dark {
  display: none;
}

.header__logo__mobile__scrolled {
  display: none;
}

@media (--tablet) {
  .header__logo img {
    --size: 50px;
  }
}

@media (--mobile) {
  .header__logo img {
    --size: 120px;
  }

  .header__logo__light {
    display: none;
  }

  .header__logo__dark {
    display: block;
  }
}

.header__part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (--header-breakpoint) {
  .header__part {
    display: none;
  }
}

.header__part--left {
  justify-content: flex-end;
}

.header__nav {
  display: flex;
  align-items: center;
}

* + .header__nav {
  margin-left: 28px;
}

.header__nav__item {
  height: 100%;
  list-style: none;
}

.header__nav__item a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--header-padding-top) 0;
  padding-left: calc(var(--gap) / 2);
  padding-right: calc(var(--gap) / 2);

  font-family: var(--font-family-heading);
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--black);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  position: relative;
}

@media (pointer: fine) {
  .header__nav__item a span {
    position: relative;
  }

  .header__nav__item a span::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    height: 2px;
    background: var(--black);
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
    bottom: -5px;
  }

  .header__nav__item a:hover span::after {
    transform: scale(1);
  }
}

@media (--tablet) {
  .header__nav__item a {
    font-size: 15px;
  }
}

.header__ctas {
  padding-top: 4px;

  list-style: none;
}
* + .header__ctas {
  margin-left: calc(var(--gap) / 2);
}

.header__ctas .button {
  min-width: 160px;
}

.header__burger {
  position: absolute;
  top: 0;
  right: 0;

  align-items: center;
  justify-content: center;
  width: 32px;
  height: 100px;
  margin-left: auto;

  background: none;
  border: none;
  cursor: pointer;
  display: none;
  margin-right: 3%;
}
.header__burger.header__burger--touch-down {
  transform: scale(0.85);

  transition-duration: 180ms;
}
@media (--header-breakpoint) {
  .header__burger {
    display: flex;
    position: relative;
    height: 100%;
  }
}

.js-focus-visible .header__burger:focus:not(.focus-visible) {
  outline: none;
}

.header__burger__icon {
  width: 25px;
  height: 2px;
  position: relative;
  background-color: var(--black);

  margin-top: -2px;
}

.header__burger__icon::before,
.header__burger__icon::after {
  position: absolute;
  display: block;
  height: 2px;

  background-color: var(--black);
  content: '';
}

.header__burger__icon::before {
  width: 100%;
  top: -9px;
}

.header__burger__icon::after {
  width: 100%;
  bottom: -9px;
  right: 0;
}

.header__burger__text {
  margin-left: 10px;

  color: var(--black);
}
