* + .product-hero {
  margin-top: calc(var(--header-height) + 60px);
}

@media (--mobile) {
  * + .product-hero {
    margin-top: calc(var(--header-height) + 16px);
  }
}

.product-hero {
  --wrap-text-height: 125px;
}

.product-hero__content {
  display: grid;
  grid-template-columns: 1fr 476px;
  gap: 24px;
}

@media (--tablet) {
  .product-hero__content {
    grid-template-columns: 1fr 400px;
  }
}

@media (--mobile) {
  .product-hero__content {
    display: block;
  }

  * + .product-hero__content__informations {
    margin-top: 40px;
  }
}

* + .product-hero__content {
  margin-top: 16px;
}

.product-hero__content__informations__title {
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  color: #000;
  text-transform: uppercase;
}

@media (--mobile) {
  .product-hero__content__informations__title {
    font-size: 20px;
  }
}

* + .product-hero__content__informations__particularities {
  margin-top: 16px;
}

.product-hero__content__informations__particularities {
  display: flex;
}

* + .product-hero__content__informations__particularity {
  margin-left: 24px;
}

.product-hero__content__informations__particularity {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.product-hero__content__informations__particularity svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  transform: translateY(3px);
}

* + .product-hero__content__informations__separator {
  margin-top: 16px;
}

.product-hero__content__informations__separator {
  height: 0;

  border: none;
  border-top: solid 1px rgb(0 0 0 / 10%);
}

* + .product-hero__content__informations__choice {
  margin-top: 16px;
}

.product-hero__content__informations__description {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.7px;
  color: rgb(var(--black-rgb) 0.7);
}

.product-hero__content__informations__description--mobile,
.product-hero__content__informations__description__gradient__wrap,
.product-hero__content__informations__description__read-more {
  display: none;
}

@media (--mobile) {
  .product-hero__content__informations__description__gradient__wrap {
    display: block;
    overflow: hidden;
  }

  .product-hero__content__informations__description__wrap {
    position: relative;

    overflow: hidden;
    max-height: var(--wrap-text-height);
  }

  .product-hero__content__informations__description__wrap--full-height {
    max-height: 100%;
  }

  .product-hero__content__informations__description__wrap:not(
      .product-hero__content__informations__description__wrap--full-height
    )::after {
    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 1;

    content: '';
    width: 100%;
    height: 75px;

    background: linear-gradient(
      0deg,
      #fdfdfd 13.16%,
      rgb(253 253 253 / 0%) 113.82%
    );
  }

  .product-hero__content__informations__description__wrap--full-height
    + .product-hero__content__informations__description__read-more {
    display: none;
  }

  .product-hero__content__informations__description__gradient__wrap {
    position: relative;
  }

  .product-hero__content__informations__description__read-more {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
    margin-right: auto;

    margin-left: auto;

    font-size: 14px;
    font-weight: 500;
    line-height: 150%;

    color: #000;
  }

  a.product-hero__content__informations__description__read-more {
    position: relative;
  }

  a.product-hero__content__informations__description__read-more::before {
    position: absolute;
    top: calc(-1 * var(--wrap-text-height));
    right: -10px;
    bottom: -10px;
    left: -10px;
    z-index: 1;

    content: '';
  }

  .product-hero__content__informations__description__read-more img {
    width: 100%;
    height: 100%;
  }

  .product-hero__content__informations__description--mobile {
    display: block;

    overflow: hidden;
  }

  .product-hero__content__informations__description--desktop,
  .product-hero__content__informations__choice__title,
  .product-hero__content__informations__separator {
    display: none;
  }

  .product-hero__content__informations__choice__mobile-grid {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    gap: 28px;
  }

  .product-hero__content__informations__choice__options {
    order: 2;
  }

  .product-hero__content__informations__choice__price {
    font-size: 22px !important;
  }
}

* + .product-hero__content__informations__description__wrap,
* + .product-hero__content__informations__description {
  margin-top: 32px;
}

.product-hero__content__informations__description h2 {
  font-family: var(--font-family-heading);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: var(--black);
  text-transform: uppercase;
}

.product-hero__content__informations__description ul {
  color: var(--black);
  list-style: none;
}

.product-hero__content__informations__description * + ul {
  margin-top: 16px;
}

.product-hero__content__informations__description ul * + li {
  margin-top: 8px;
}

.product-hero__content__informations__description p + p {
  margin-top: 16px;
}

.product-hero__content__informations__description ul li p {
  display: flex;
  align-items: center;
  gap: 14px;
}

.product-hero__content__informations__description ul li img {
  --size: 18px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}

.product-hero__content__informations__choice__title {
  font-family: var(--font-family-heading);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.product-hero__content__informations__description > * a {
  font-weight: 600;
  color: #7a612e;
  text-decoration: none;
}

.product-hero__content__informations__description > * a:hover {
  text-decoration: underline;
}

* + .product-hero__content__informations__choice__mobile-grid {
  margin-top: 16px;
}

.product-hero__content__informations__choice__options {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media (--mobile) {
  .product-hero__content__informations__choice__options {
    flex-wrap: nowrap;
    justify-content: flex-end;
    gap: 6px;
    white-space: nowrap;
  }
}

.product-hero__content__informations__choice__option {
  padding: 8px 20px;

  font-size: 14px;
  color: var(--black);

  border: 1px solid rgb(var(--black-rgb) 0.1);
  border-radius: 999px;
  background-color: var(--white);
  user-select: none;
  transition:
    200ms border-color,
    200ms background-color;
}

@media (--mobile) {
  .product-hero__content__informations__choice__option {
    padding: 8px 16px;
  }
}

.product-hero__content__informations__choice__option:not(
    .product-hero__content__informations__choice__option--active
  ):hover {
  background-color: rgb(var(--black-rgb) 0.05);
  border-color: var(--black);
}

.product-hero__content__informations__choice__option--active {
  color: var(--white);

  background: var(--black);
}

* + .product-hero__content__informations__choice__price {
  margin-top: 24px;
}

.product-hero__content__informations__choice__price {
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 1.4px;
  color: var(--black);
}

* + .product-hero__content__informations__choice__purchase {
  margin-top: 40px;
}

.product-hero__content__informations__purchase__subscription .button + .button {
  margin-top: 12px;
}

@media (--mobile) {
  * + .product-hero__content__informations__choice__purchase {
    margin-top: 16px;
  }

  .product-hero__content__informations__choice__price {
    display: flex;

    align-items: center;
    margin-top: 0;
  }
}

.product-hero__content__informations__choice__purchase {
  display: flex;
  gap: 20px;
}

* + .product-hero__content__informations__purchase__subscription {
  margin-top: 16px;
}

.product-hero__content__informations__choice__purchase .edit-quantity__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-hero__content__informations__choice__purschase__quantity {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100px;

  font-family: var(--font-family-heading);
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.36px;

  border: 1px solid rgb(var(--black-rgb) 0.1);

  border-radius: 5px;
  text-align: center;
  user-select: none;
}

.product-hero__content__informations__choice__purschase__quantity p {
  transform: translateY(35%);
}

.product-hero__content__informations__choice__purschase__quantity button {
  position: relative;

  transition: background-color 200ms;
}

.product-hero__content__informations__choice__purschase__quantity button:hover {
  background-color: rgb(var(--black-rgb) 0.02);
}

.product-hero__content__informations__choice__purschase__quantity
  button:active {
  background-color: rgb(var(--black-rgb) 0.05);
}

.product-hero__content__informations__choice__purschase__quantity
  button:first-child::after,
.product-hero__content__informations__choice__purschase__quantity
  button:last-child::before {
  --transition-duration: 400ms;

  position: absolute;
  top: 0;

  content: '';
  width: 0;
  height: 100%;

  border-left: 1px solid rgb(var(--black-rgb) 0.2);

  transform-origin: center;
  transform: scaleY(0.5);
  transition: transform var(--transition-duration);
}

.product-hero__content__informations__choice__purschase__quantity
  button:first-child::after {
  right: 0;
}

.product-hero__content__informations__choice__purschase__quantity
  button:last-child::before {
  left: 0;
}

.product-hero__content__informations__choice__purschase__quantity
  button:hover:first-child::after,
.product-hero__content__informations__choice__purschase__quantity
  button:hover:last-child::before {
  transform: scaleY(1);
}

.product-hero__content__informations__choice__purschase__quantity
  button:active:first-child::after,
.product-hero__content__informations__choice__purschase__quantity
  button:active:last-child::before {
  --transition-duration: 100ms;

  transform: scaleY(0.8);
}

.product-hero__content__informations__choice__purchase__button {
  flex: 1;

  padding: 16px;

  font-family: var(--font-family-heading);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.84px;

  color: var(--white);
  text-transform: uppercase;

  background-color: var(--black);
}
