/* * + .product-rating {
  margin-top: 60px;
}

@media (--mobile) {
  * + .product-rating {
    margin-top: 40px;
  }
} */

.product-rating {
  --js-scroll-offset-top: 170;

  padding: 60px 0;
}
@media (--mobile) {
  .product-rating {
    --js-scroll-offset-top: 110;

    padding: 30px 0;
  }
}

* + .product-rating__content {
  margin-top: 40px;
}

.product-rating__content {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  gap: 100px;
}

@media (--mobile) {
  .product-rating__content {
    display: block;
  }

  * + .product-rating__reviews {
    margin-top: 40px;
  }
}

.product-rating__left__title {
  color: black;
  font-family: var(--font-family-heading);

  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
}

@media (--mobile) {
  .product-rating__left__title {
    font-size: 24px;
    letter-spacing: 2.4px;
    text-align: center;
  }
}

.product-rating__left__summary {
  display: flex;
}

.product-rating__left__summary__rating {
  color: #000;
  font-family: var(--font-family);
  font-size: 42px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 2.1px;
}

* + .product-rating__left__summary__stars {
  margin-left: 20px;
}

* + .product-rating__left__summary__stars p {
  color: #000;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.7px;
}

* + .product-rating__left__description {
  margin-top: 20px;
}

.product-rating__left__description {
  color: #000;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

* + .product-rating__left__progress {
  margin-top: 20px;
}

* + .product-rating-progress {
  margin-top: 15px;
}

.product-rating-progress {
  display: flex;
  color: #000;
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.9px;
  text-transform: capitalize;
}

* + .product-rating-progress__progress-bar {
  margin-left: 11px;
}

.product-rating-progress__progress-bar {
  position: relative;
  width: 274px;
  height: 15.6px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.05);
}

.product-rating-progress__progress-bar__fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--progress-width);
  background-color: black;
  border-radius: 24px;
}

* + .product-rating__review {
  margin-top: 30px;
}

.product-rating__review {
  position: relative;

  padding-bottom: 40px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

@media (--mobile) {
  .product-rating__review:last-of-type {
    border: none;
  }
}

.product-rating__review__date {
  position: absolute;
  top: 0;
  right: 0;

  color: rgba(0, 0, 0, 0.15);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.7px;
}

@media (--mobile) {
  .product-rating__review {
    padding: 40px 0;
    margin-top: 0;
  }

  .product-rating__review__date {
    top: 40px;
  }

  .product-rating__review:first-of-type {
    padding-top: 0;
  }

  .product-rating__review:first-of-type .product-rating__review__date {
    top: 0;
  }

  * + .product-rating__review {
    border-top: solid 1px rgba(0, 0, 0, 0.1);
  }
}

.product-rating__review__top {
  display: flex;
}

* + .product-rating-stars {
  margin-left: 16px;
}

.product-rating__review__top .product-rating-stars svg * {
  fill: #ffd905;
}

.product-rating__review__top .product-rating-stars {
  transform: translateY(-2px);
}

* + .product-rating__review__content {
  margin-top: 22px;
}

.product-rating__review__top h3 {
  color: #000;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.75px;
}

.product-rating__review__content {
  color: #000;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.7px;
}
