.all-products + .cta {
  margin-top: 48px;
}

.all-recipes + .cta {
  margin-top: 48px;
}

.product-discover + .cta {
  margin-top: 120px;
}
@media (--mobile) {
  .product-discover + .cta {
    margin-top: 64px;
  }
}

.cta {
  padding: 100px 0;
  background-color: #f6f4f0;
}
@media (--tablet) {
  .cta {
    padding: 64px 0;
  }
}
@media (--mobile) {
  .cta {
    padding: 50px 0;
  }
}

.cta__wrap {
  max-width: 58ch;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.cta__illustration {
  width: 200px;
  height: auto;
}
@media (--mobile) {
  .cta__illustration {
    width: 160px;
  }
}

* + .cta__title {
  margin-top: 40px;
}
@media (--mobile) {
  * + .cta__title {
    margin-top: 20px;
  }
}

.cta__title h2 {
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
@media (--mobile) {
  .cta__title h2 {
    font-size: 18px;
  }
}

.cta__content {
  margin-top: 24px;
}
@media (--mobile) {
  .cta__content {
    margin-top: 16px;
  }
}

.cta__content p {
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%;
}

@media (--mobile) {
  .cta__content p {
    font-size: 14px;
    line-height: 150%;
  }
}

.cta__button {
  margin-top: 48px;
}
@media (--mobile) {
  .cta__button {
    margin-top: 24px;
  }
}

.cta__button a {
  width: 100%;
}
