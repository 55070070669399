.product-hero-breadcrumb {
  display: flex;
  list-style: none;
}

* + .product-hero-breadcrumb__item {
  margin-left: 5px;
}

.product-hero-breadcrumb__item span,
.product-hero-breadcrumb__item a {
  font-family: var(--font-family-heading);

  font-size: 14px;
  font-weight: 500;
  line-height: 14px; /* 100% */
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
}

* + .product-hero-breadcrumb__item__icon {
  margin-left: 5px;
}
