.highlights {
  --min-height: 380px;

  position: relative;
  z-index: 1;
}

* + .highlights {
  margin-top: 32px;
}
@media (--tablet) {
  * + .highlights {
    margin-top: 50px;
  }
}
@media (--mobile) {
  * + .highlights {
    margin-top: 28px;
  }
}

.subject + .highlights {
  margin-top: 80px;
}
@media (--tablet) {
  .subject + .highlights {
    margin-top: 64px;
  }
}
@media (--mobile) {
  .subject + .highlights {
    margin-top: 32px;
  }
}

.highlights__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  overflow: hidden;
}
@media (--tablet) {
  .highlights__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .highlights__container {
    grid-template-columns: 1fr;
  }
}
