.page {
  overflow: hidden;
}

.page__wrap {
  position: relative;
}

.not-found + .page__bottom-margin,
.faq + .page__bottom-margin,
.recipe-preparation + .page__bottom-margin,
.recipe-discover + .page__bottom-margin,
.highlights + .page__bottom-margin,
.image-text + .page__bottom-margin,
.texte + .page__bottom-margin,
.article-selection + .page__bottom-margin {
  margin-top: 92px;
}
@media (--tablet) {
  .not-found + .page__bottom-margin,
  .faq + .page__bottom-margin,
  .recipe-preparation + .page__bottom-margin,
  .recipe-discover + .page__bottom-margin,
  .highlights + .page__bottom-margin,
  .image-text + .page__bottom-margin,
  .texte + .page__bottom-margin,
  .article-selection + .page__bottom-margin {
    margin-top: 64px;
  }
}
@media (--mobile) {
  .not-found + .page__bottom-margin,
  .faq + .page__bottom-margin,
  .recipe-preparation + .page__bottom-margin,
  .recipe-discover + .page__bottom-margin,
  .highlights + .page__bottom-margin,
  .image-text + .page__bottom-margin,
  .texte + .page__bottom-margin,
  .article-selection + .page__bottom-margin {
    margin-top: 32px;
  }
}
