.slider {
  --slide-transition: 0.8s;
  --slide-width: 550px;
  --offset: 48px;
  --js-scroll-offset-top: 230;

  position: relative;
  overflow: hidden;
}

@media (--mobile) {
  .slider {
    --js-scroll-offset-top: 170;
  }
}

* + .slider {
  margin-top: 75px;
}

@media (--mobile) {
  .product-breadcrumb + .slider {
    margin-top: 10px;
  }
}

.slider__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  user-select: none;
}
@media (--mobile) {
  .slider {
    padding-top: 150px;
  }
  .slider__background {
    height: 150px;
  }
}

.slider__background--active {
  opacity: 1;
}

.slider__block {
  position: relative;
  width: var(--slide-width);
  margin-left: 48px;
  overflow: hidden;
}

.slider__block__container {
  position: absolute;
  user-select: none;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
}

.slider__block__container::-webkit-scrollbar {
  display: none;
}

@media (--tablet) {
  .slider__block {
    margin: 0 auto;
  }

  .slider .max-width {
    padding: 0;
  }
}

@media (--mobile) {
  .slider__block {
    --slide-width: 100vw;
  }

  .slider__block__container {
    overflow: auto;
  }
}

.slider__block:before,
.slider__block:after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: var(--offset);
  content: '';
}

.slider__block:before {
  left: 0;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.8) 30%,
    rgba(255, 255, 255, 0)
  );
}

.slider__block:after {
  right: 0;
  background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.8) 30%,
    rgba(255, 255, 255, 0)
  );
}

.slider__block__container__wrap {
  scroll-snap-type: x mandatory;
  display: flex;
  position: absolute;
  overflow: visible;
  height: 100%;
  top: 0;
}

.slider__block__model {
  width: 9000vw;
  display: flex;
  opacity: 0;
}

@media (--mobile) {
  .slider__block__model {
    padding-bottom: 30px;
  }
}

.slider__block__container__wrap__slide,
.slider__block__model__slide {
  width: var(--slide-width);
  background-color: var(--white);
  padding: 42px var(--offset) 90px var(--offset);
  scroll-snap-align: center;
}

.slider__block__model__slide--solo {
  padding: 48px;
}

.slider__block__container__wrap__slide__title h3,
.slider__block__model__slide__title h3 {
  font-family: var(--font-family-heading);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.25;
  text-align: center;
  color: var(--black);
  text-transform: uppercase;
}

@media (--mobile) {
  .slider__block__container__wrap__slide__title h3,
  .slider__block__model__slide__title h3 {
    letter-spacing: 1px;
    font-size: 24px;
  }
}

.slider__block__container__wrap__slide__content p,
.slider__block__model__slide__content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: black;
  text-align: center;
}

.slider__block__container__wrap__slide__title
  + .slider__block__container__wrap__slide__content,
.slider__block__model__slide__title + .slider__block__model__slide__content {
  margin-top: 25px;
}

* + .slider__block__container__wrap__slide__content p:not(:first-of-type),
* + .slider__block__model__slide__content p:not(:first-of-type) {
  margin-top: 25px;
}

.slider__block__bottom {
  --width: 100%;
  position: absolute;
  bottom: 10px;
  width: var(--width);
  left: calc((100% - var(--width)) / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.slider__block__bottom__button {
  opacity: 0.2;
  padding: 40px 0;
  transition: opacity 1s ease-in-out;
  width: 52px;
}

.slider__block__bottom__button:hover {
  opacity: 1;
}

.slider__block__bottom__button--active {
  opacity: 1;
}

.slider__block__bottom__button div {
  width: 36px;
  border: 1px solid #000000;
  margin: 0 auto;
}

@media (--mobile) {
  .slider__block__bottom {
    bottom: 25px;
  }
}
