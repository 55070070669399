.history__line {
  display: none;

  @media (--mobile) {
    display: block;

    padding-top: 32px;

    margin: 0 7px;

    border: none;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
