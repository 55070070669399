.text {
  font-size: 16px;
  line-height: 1.25;
  color: black;
  padding-bottom: 50px;
}

* + .text {
  margin-top: 64px;
}
@media (--mobile) {
  * + .text {
    margin-top: 48px;
  }
}

.text .max-width {
  --max-width: 700px;
}

.text h2 {
  font-weight: 600;
  font-size: 24px;
}
@media (--mobile) {
  .text h2 {
    font-size: 20px;
  }
}

.text * + h2 {
  margin-top: 24px;
}

.text * + p {
  margin-top: 16px;
}

.text a {
  color: var(--secondary-500);
  text-decoration: none;
}
.text a:hover {
  text-decoration: underline;
}
