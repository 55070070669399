.cart__open-cart {
  --size: 32px;

  position: relative;

  display: block;
  width: var(--size);
  height: var(--size);

  cursor: pointer;
}

@media (--header-breakpoint) {
  .cart__open-cart {
    height: 100%;
    right: 14px;
  }
}

.cart__open-cart img {
  --size: 26px;

  width: var(--size);
  height: var(--size);
}

.cart__open-cart__loading {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  padding: 2px;
  background-color: white;
  border-radius: 999px;

  transform: translate(-50%, -50%) translateY(1px);
}

.cart__open-cart__quantity {
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  min-width: 15px;
  padding: 0px 4px;

  color: var(--white);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.8px;

  border-radius: 999px;
  background-color: rgba(var(--black-rgb, 0.8));
  animation: cart__open-cart__quantity-in 500ms;
}
@keyframes cart__open-cart__quantity-in {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.cart__open-cart__quantity span {
  transform: translateX(0.5px);
}
