.recipe-hero {
  --icon-size: 18px;
  --tags-height: 32px;

  margin-top: calc(64px + var(--header-height));
}
@media (--mobile) {
  .recipe-hero {
    margin-top: calc(24px + var(--header-height));
  }
}

.recipe-hero__title {
  max-width: 24ch;
  margin: 0 auto;
  color: black;
  text-align: center;
  font-family: var(--font-family-heading);
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

* + .recipe-hero__content {
  margin-top: 29px;
}

.recipe-hero__content {
  position: relative;
  height: 403px;
  width: 100%;
}

@media (--mobile) {
  .recipe-hero {
    padding-bottom: 48px;
  }

  .recipe-hero__title {
    font-size: 24px;
  }
}

.recipe-hero__content__background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recipe-hero__content__tags {
  position: absolute;
  z-index: 1;
  height: var(--tags-height);
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  bottom: 24px;
  left: 0;
  width: 100%;
}

@media (--mobile) {
  .recipe-hero__content__tags {
    right: 0;
    bottom: calc(-1 * (var(--tags-height) + 18px));
    gap: 7px;
  }
}

.recipe-hero__content__tag {
  display: grid;
  grid-template-columns: var(--icon-size) 1fr;
  gap: 10px;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(4px);
  padding: 8px 24px;

  color: #000;
  text-align: center;
  font-family: var(--font-family);

  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
}

@media (--mobile) {
  .recipe-hero__content__tag--hide-mobile {
    display: none;
  }
}

.recipe-hero__content__tag__image {
  width: var(--icon-size);
  height: var(--icon-size);
}

.recipe-hero__content__tag__content {
  transform: translateY(3px);
}

@media (--mobile) {
  .recipe-hero__content__tag {
    background: #f6f4f0;
  }

  .recipe-hero__content__tag__content {
    white-space: nowrap;
  }
}
