.grid {
  --columns: 1;
  --gap: 0px;
  --row-gap: var(--gap);

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  column-gap: var(--gap);
  row-gap: var(--row-gap);
}

.grid--columns-1 {
  --columns: 1;
}

.grid--columns-2 {
  --columns: 2;
  --gap: 32px;
}
@media (--mobile) {
  .grid--columns-2 {
    --columns: 1;
  }
}

.grid--columns-3 {
  --columns: 3;
  --gap: 40px;
}
@media (--mobile) {
  .grid--columns-3 {
    --columns: 1;
  }
}

@media (--mobile) {
  .grid--mobile-columns-2 {
    --columns: 2;
    --gap: 24px;
    --row-gap: 48px;
  }
}
