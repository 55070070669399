* + .story-preview {
  margin-top: 88px;
}

@media (--tablet) {
  * + .story-preview {
    margin-top: 50px;
  }
}

@media (--mobile) {
  * + .story-preview {
    margin-top: 27px;
  }
}

@media (--mobile) {
  .story-preview > .max-width {
    max-width: 100%;
    padding: 0;
  }
}

.story-preview__container {
  position: relative;

  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 32px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

@media (--tablet) {
  .story-preview__container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (--mobile) {
  .story-preview__container {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

.story-preview__illustration {
  position: relative;
  width: 100%;
  height: 100%;

  max-height: 495px;

  overflow: hidden;
}

@media (--tablet) {
  .story-preview__illustration {
    max-height: 100%;
  }
}

@media (--mobile) {
  .story-preview__illustration {
    height: 355px;
  }
}

.story-preview__image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.story-preview__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.story-preview__image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    182deg,
    rgba(0, 0, 0, 0) 56.33%,
    rgba(0, 0, 0, 0.3) 101.93%
  );
}

@media (--mobile) {
  .story-preview__illustration__icon {
    display: none;
  }
}

.story-preview__illustration__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: var(--white);
  padding-left: 24px;
  padding-bottom: 32px;
}

@media (--mobile) {
  .story-preview__illustration__content {
    padding-left: 20px;
    padding-bottom: 16px;
  }
}

.story-preview__illustration__content__text {
  width: 100%;
  padding-left: 18px;
}

@media (--mobile) {
  .story-preview__illustration__content__text {
    padding-left: 0;
  }
}

.story-preview__illustration__content__text__legend {
  font-family: var(--font-family-heading);
  font-weight: 500;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@media (--mobile) {
  .story-preview__illustration__content__text__legend {
    font-size: 16px;
  }
}

.story-preview__illustration__content__text__location {
  font-family: var(--font-family-heading);
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@media (--mobile) {
  .story-preview__illustration__content__text__location {
    font-size: 14px;
  }
}

* + .story-preview__illustration__content__text__location {
  margin-top: 4px;
}

.story-preview__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (--mobile) {
  .story-preview__content {
    padding-left: var(--page-left-padding);
    padding-right: var(--page-right-padding);
  }
}

.story-preview__content__text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.story-preview__content__text > * + p {
  margin-top: 24px;
}

.story-preview__content__text pre,
.story-preview__content__text blockquote {
  position: relative;
  width: 100%;
  white-space: normal;
  max-width: 369px;
  line-height: 30px;
  letter-spacing: 0.02em;

  padding-left: 42px;
}

.story-preview__content__text pre,
.story-preview__content__text code,
.story-preview__content__text blockquote p {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 18px;
}

.story-preview__content__text pre::before,
.story-preview__content__text blockquote::before {
  --size: 25px;
  content: '';

  position: absolute;

  top: 0;
  left: 0;

  transform: translateY(20%);

  background-image: url('/quote.svg');
  background-repeat: no-repeat;
  background-size: contain;

  width: var(--size);
  height: var(--size);
}

.story-preview__content__text > * + pre,
.story-preview__content__text > * + blockquote {
  margin-top: 24px;
}

.story-preview__content__text em {
  font-family: var(--font-family-heading);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  letter-spacing: 0.02em;
  font-style: italic;
}
