* + .promotion {
  margin-top: 75px;
}

.promotion {
  position: relative;
}

.promotion img {
  width: 100vw;
  height: auto;
}

@media (--mobile) {
  * + .promotion {
    margin-top: 50px;
  }

  .promotion img {
    height: 140px;
    object-fit: cover;
  }
}
