.product-hero-slider__mini-map {
  --padding: 16px;

  position: relative;

  margin-top: calc(16px - var(--padding));
  margin-right: calc(-1 * var(--padding));
  margin-bottom: calc(-1 * var(--padding));
  margin-left: calc(-1 * var(--padding));

  width: calc(100% + var(--padding));
}
@media (max-width: 1000px) {
  .product-hero-slider__mini-map {
    --padding: 8px;

    margin-right: 0;
  }
}

.product-hero-slider__mini-map::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  width: var(--padding);

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  content: '';
  pointer-events: none;
}

.product-hero-slider__mini-map::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: var(--padding);

  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  content: '';
  pointer-events: none;
}

.product-hero-slider__mini-map > ul {
  display: flex;
  width: 100%;
  padding: var(--padding);
  overflow-x: auto;

  list-style: none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
@media (max-width: 600px) {
  .product-hero-slider__mini-map > ul {
    display: none;
  }
}

.product-hero-slider__mini-map > ul::-webkit-scrollbar {
  display: none;
}

.product-hero-slider__mini-map > ul::after {
  display: block;
  width: 0;
  margin-left: 16px;

  content: '\A0';
}

.product-hero-slider__mini-map > ul > li {
  flex: 0 0 110px;
  width: 110px;
  height: 110px;

  white-space: nowrap;

  background-color: white;

  scroll-snap-align: center;
  scroll-snap-stop: always;
}
.product-hero-slider__mini-map > ul > li + li {
  margin-left: 16px;
}

.product-hero-slider__mini-map > ul > li > button {
  width: 100%;
  height: 100%;
  overflow: hidden;

  background: none;
  box-shadow: 0 0 0 2px rgba(9, 65, 222, 0);

  transition: 150ms box-shadow;
}

.product-hero-slider__mini-map > ul > li > button:focus {
  outline: none;
}

.product-hero-slider__mini-map__image {
  width: 100%;
  height: 100%;
  padding: 14px;
  object-fit: contain;
}
@media (max-width: 600px) {
  .product-hero-slider__mini-map__image {
    max-width: 100%;
    padding: 10px;
  }
}

.product-hero-slider__mini-map__image.product-hero-slider__mini-map__image--cover {
  min-height: auto;
  padding: 0;
  object-fit: cover;
}

.product-hero-slider__mini-map__item {
  border: 3px solid rgba(0, 0, 0, 0);

  transition: border-color 0.3s ease-in-out;
}

.product-hero-slider__mini-map__item--active {
  border: 3px solid rgba(0, 0, 0, 1);
}
