.history-information {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 79px 0 99px 0;

  @media (--mobile) {
    margin: 32px 0;

    text-align: start;
  }
}

.history-information__title {
  font-family: var(--font-family-heading);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;

  @media (--mobile) {
    font-size: 21px;
    letter-spacing: 0.63px;
  }
}

.history-information__title + .history-information__text {
  margin-top: 32px;
}

.history-information__text {
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin: 0 66px 0 66px;

  font-family: var(--font-family);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.395px;
  letter-spacing: 0.8px;

  @media (--mobile) {
    margin: unset;

    font-size: 14px;
    letter-spacing: 0.7px;
  }
}

.history-information__text + .history-information__container {
  margin-top: 80px;

  @media (--mobile) {
    margin-top: 27px;
  }
}

.history-information__container {
  display: flex;

  gap: 163px;

  justify-content: center;

  @media (--mobile) {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}

.history-information__card-content {
  margin-top: auto;
}

.history-information__card {
  display: flex;
  max-width: 134px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.history-information__card-description {
  font-family: var(--font-family);
  color: #776c57;

  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 149.7%;

  @media (--mobile) {
    text-align: center;
    font-size: 15px;
    line-height: 129.7%;
  }
}
