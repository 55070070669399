.video {
  --padding-bottom: 260px;

  position: relative;
  right: 50%;
  left: 50%;

  width: 100vw;
  margin-right: -50vw;
  margin-bottom: var(--margin-bottom);
  margin-left: -50vw;
}

@media (--tablet) {
  .video {
    --padding-bottom: 320px;
  }
}

@media (--mobile) {
  .video {
    --padding-bottom: 0;

    display: flex;
    flex-direction: column-reverse;
  }
}

* + .video {
  margin-top: 75px;
}
@media (--tablet) {
  * + .video {
    margin-top: 50px;
  }
}

.video__wrapper > .max-width {
  height: 100%;
}

.video__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

@media (--mobile) {
  .video__wrapper {
    position: static;
  }
  .video.video--started.video--playing .video__wrapper {
    pointer-events: auto;
  }
}

.video__wrap {
  display: block;

  align-items: center;
  height: 100%;
}

@media (--mobile) {
  .video__wrap {
    position: static;

    display: block;
    padding-bottom: 30px;
  }
}

.video__content {
  transition: 400ms opacity, 400ms transform;

  will-change: transform;
}
.video.video--started.video--playing .video__content {
  transform: translateX(-10%);
  opacity: 0;

  pointer-events: none;
}
@media (--mobile) {
  .video.video--started.video--playing .video__content {
    transform: none;
    opacity: 1;

    pointer-events: auto;
  }
}

.video__content h2 {
  max-width: 24ch;

  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;

  @media (--tablet) {
    font-size: 20px;
  }

  @media (--mobile) {
    color: var(--dark);
  }
}
.video__content h2 > strong {
  color: var(--primary);
}

.video__content > p {
  max-width: 42.5ch;

  color: var(--background-color);

  font-weight: 500;
  font-size: 16px;
  line-height: 1.8;

  @media (--tablet) {
    font-size: 15px;
  }

  @media (--mobile) {
    color: var(--black);
  }
}

.video__content > * + p {
  margin-top: 16px;

  @media (--tablet) {
    margin-top: 12px;
  }
}

.video__player {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video__player--hasnt-video {
  padding-bottom: 300px;
}

@media (min-width: 1400px) {
  .video__player {
    padding-bottom: 788px;

    transition: 50ms padding-bottom;
  }

  .video__player--hasnt-video {
    padding-bottom: 450px;
  }

  .video.video--started .video__player {
    padding-bottom: 56.25%;
  }
}
.video__player__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video__player__cover > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: var(--padding-bottom);
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
}

.video.video--is-mobile.video--started .video__player__cover > img {
  opacity: 0;

  transition: 200ms opacity;
}

.video__player__cover::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 53.93%,
      #ffffff 86.84%
    ),
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 25.64%,
      rgba(255, 255, 255, 0) 70.82%
    );

  content: '';
}

@media (--mobile) {
  .video__player__cover > img {
    bottom: 0;

    height: 100%;
  }

  .video__content {
    color: black;
  }
}

.video__player__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  opacity: 0;
}
.video.video--ready .video__player__wrap {
  opacity: 1;

  transition: 200ms opacity;
}
.video.video--is-mobile .video__player__wrap,
.video.video--ready.video--is-mobile .video__player__wrap {
  opacity: 0;

  transition: 300ms opacity;
}
.video.video--is-mobile.video--started .video__player__wrap,
.video.video--ready.video--is-mobile.video--started .video__player__wrap {
  opacity: 1;
}

.video__player__wrap::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  height: 300px;

  transition: 500ms opacity;

  content: '';
  pointer-events: none;
}
.video.video--started.video--playing .video__player__wrap::after {
  transition: 2000ms opacity;
}
@media (--mobile) {
  .video__player__wrap::after {
    display: none;
  }
}

.video__player__wrap > * {
  position: relative;

  top: -50%;

  transform: translateY(-6px);
}
.video.video--is-mobile .video__player__wrap > * {
  top: 0;
}

.video__player__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  transition: 500ms opacity;

  content: '';
  pointer-events: none;
}
.video.video--started.video--playing .video__player__gradient {
  opacity: 0;

  transition: 2000ms opacity;
}

@media (--mobile) {
  .video__player__gradient {
    display: none;
  }
}

.video__control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.video.video--is-mobile .video__control {
  display: none;
}

.video__control__play-pause {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: default;
}
.video.video--started.video--playing .video__control__play-pause {
  z-index: 3;
}
.video__control__play-pause:focus {
  outline: none;
}

.video__play {
  display: block;
  width: 100%;
  height: 100%;

  color: #ffffff;

  transition: 400ms opacity, 400ms transform;

  will-change: opacity, transform;
}
.video.video--started.video--playing .video__play {
  transform: scale(0.8);
  opacity: 0;

  transition: 300ms opacity, 300ms transform;

  pointer-events: none;
}

@media (--mobile) {
  .video__play {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    width: auto;
    height: 0;
    padding-bottom: 56.25%;
  }
}

.video__play > button {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;

  cursor: pointer;

  user-select: none;
}
.video__play > button:focus {
  outline: none;
}
.video.video--started.video--playing .video__play > button {
  cursor: default;
}

@media (--mobile) {
  .video__play > button {
    position: absolute;
    top: 50%;
    left: 50%;

    font-size: 17px;

    transform: translateX(-50%) translateY(-50%);
  }
}

.video__play > button > .video__play__img {
  display: flex;
  width: 87px;
  height: 87px;

  transition: 200ms transform;
}
@media (--mobile) {
  .video__play > button > .video__play__img {
    width: 60px;
    height: 60px;
  }
}

.video__play > button > .video__play__img::before {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}

.video__play > button > .video__play__img img {
  position: relative;

  width: 100%;
  height: 100%;
  object-fit: contain;

  transition: 200ms transform;
}
.video__play > button:hover > .video__play__img {
  transform: scale(1.1);

  transition: 200ms transform;
}
.video__play > button:active > .video__play__img img {
  transform: scale(0.95);

  transition: 100ms transform;
}

.video__play > button > .video__play__img + span {
  display: block;
  margin-left: 20px;
  padding-top: 2%;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
}
@media (--mobile) {
  .video__play > button > .video__play__img + span {
    margin-left: 12px;

    font-size: 20px;
  }
}
