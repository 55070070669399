.cart__modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-cart-modal);

  display: none;
  padding-top: var(--header-height);
}

.cart__modal--open {
  display: block;

  background: rgba(0, 0, 0, 0.55);

  z-index: var(--z-index-cart-modal-overlay);
}

.cart__modal__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.cart__modal__wrap {
  --padding: 24px;

  position: relative;

  width: 100%;
  max-width: 400px;
  max-height: 100%;
  margin-left: auto;
  overflow: auto;
  padding-top: var(--padding);
  padding-right: var(--page-right-padding);
  padding-bottom: var(--padding);
  padding-left: var(--page-horizontal-padding);

  background-color: var(--white);

  z-index: 10;
}
@media (--mobile) {
  .cart__modal__wrap {
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    height: 100dvh;
    max-width: 100%;
    padding-left: var(--page-right-padding);

    background-color: var(--white);
    backdrop-filter: none;

    overflow: visible;
  }
}

.cart__modal__header {
  display: flex;
  flex-direction: column;
}

.cart__modal__header p {
  margin: 16px 0;
}

.cart__modal__header__top {
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
}
.cart__modal__header h2 {
  font-family: var(--font-family-heading);
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 1.36px;
  text-transform: uppercase;
}

.cart__modal__header button {
  margin-left: 16px;
}
.cart__modal__header button img {
  --size: 18px;

  width: var(--size);
  height: var(--size);
}

* + .cart__modal__list-container {
  margin-top: 26px;
}

.cart__modal__list-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
}

.cart__modal__list-wrap {
  overflow-y: auto;
  max-height: 37.5vh;
  border-bottom: 1px solid #dedede;
  box-shadow: 0px 0px 12px 0px rgba(var(--black-rgb), 0.05);
}

.cart__modal__list-wrap::-webkit-scrollbar {
  display: none;
}

.cart__modal__list {
  --gap: 32px;

  display: block;

  list-style: none;
  padding-bottom: 12px;
}

@media (--mobile) {
  .cart__modal__list-wrap {
    height: 100%;
    max-height: 45vh;
  }
}

* + .cart__modal__list {
  margin-top: 16px;
}

.cart__modal__item {
  position: relative;

  display: flex;
  gap: 16px;
}

* + .cart__modal__item {
  margin-top: var(--gap);
}
.cart__modal__item:not(:last-of-type())::after {
  position: absolute;
  bottom: calc(-0.5 * var(--gap));

  content: '';
  display: block;
  width: 100%;
  height: 0;
  border-top: 1px solid #b1b1b1;
}

.cart__modal__item__image {
  --width: 122px;

  flex: 0 1 var(--width);
  width: var(--width);
  height: 106px;
  object-fit: cover;
}

.cart__modal__item__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cart__modal__item__top {
  display: flex;
  justify-content: space-between;
}

.cart__modal__item__title {
  position: relative;

  font-family: var(--font-family-heading);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.cart__modal__item__title__name a {
  color: var(--black);
  text-decoration: none;
}
.cart__modal__item__title__name a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.cart__modal__item__title__variation {
  font-weight: 500;
}
* + .cart__modal__item__title__variation {
  margin-top: 4px;
}

.cart__modal__item__footer {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.cart__modal__item__footer .edit-quantity {
  background: #f8f8f8;
}

.cart__modal__item__footer .edit-quantity button,
.cart__modal__item__footer .edit-quantity__text {
  padding: 6px 0px;
}

.cart__modal__item__footer__total {
  margin-left: auto;

  font-family: var(--font-family-heading);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.cart__modal__summerize {
  --gap: 32px;

  display: flex;
  flex-direction: column;
}

* + .cart__modal__summerize {
  margin-top: calc(var(--gap) - 10px);
}

.cart__modal__summerize + .button {
  margin-top: 16px;
}

* + .cart__modal__summerize__sub-total__row {
  margin-top: 9px;
}

.cart__modal__summerize__sub-total__row {
  display: flex;
  justify-content: space-between;

  color: #000;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.84px;
}

.cart__modal__summerize__sub-total__row:nth-child(1) p:nth-child(2) span {
  margin-left: 10px;
}

.cart__modal__summerize__sub-total .bold {
  font-family: var(--font-family-heading);

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 18.75px */
  letter-spacing: 1.2px;
}

.cart__modal__summerize__sub-total .light {
  font-size: 13px;
  font-weight: 300;
  line-height: 125%; /* 16.25px */
  letter-spacing: 0.78px;
}

* + .cart__modal__summerize__total {
  margin-top: 22px;
}

.cart__modal__summerize__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart__modal__summerize__total p {
  font-family: var(--font-family-heading);
  color: #000;

  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
