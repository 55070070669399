.highlights__card {
  position: relative;

  width: 100%;
  height: 100%;
  min-height: var(--min-height);
  overflow: hidden;
}
@media (--tablet) {
  .highlights__card {
    width: 100;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
}
@media (--mobile) {
  .highlights__card {
    width: 92vw;
    max-width: 580px;
    height: 100%;
    max-height: 500px;
  }
}

.highlights__card-gradient {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 39.06%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.highlights__card-background {
  width: 100%;
  height: 100%;
}

.highlights__card-background img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
  transition: transform 0.3s ease-in-out;
}

.highlights__card:hover .highlights__card-background img {
  transform: scale(1.02);
}

.highlights__card-content {
  position: absolute;

  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 40px;

  width: 100%;
  z-index: 2;
}

.highlights__card-title h3 {
  max-width: 17ch;
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-family: var(--font-family-heading);
  color: var(--white);
}

.highlights__card-description p {
  font-family: var(--font-family-heading);
  margin-top: 14px;
  max-width: 17ch;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--white);
}
