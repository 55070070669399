.product-breadcrumb {
  --tablet-offset: 8px;

  text-align: center;
}

.product-breadcrumb__content {
  position: relative;
  z-index: 8;

  display: inline-flex;
  height: 76px;
  margin: 60px auto;
  border-radius: 80px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: var(--white);

  box-shadow: 0px 4px 34px 5px rgba(120, 120, 120, 0.09);
}
@media (--tablet) {
  .product-breadcrumb__content {
    margin: 40px auto;
    height: 66px;
  }
}

* + .product-breadcrumb__content__link {
  margin-left: 24px;
}
@media (--tablet) {
  * + .product-breadcrumb__content__link {
    margin-left: 12px;
  }
}

.product-breadcrumb__content__link {
  padding: 16px 24px;
  text-transform: uppercase;

  color: black;
  border: 1px solid white;

  text-decoration: none;

  text-align: center;
  font-family: var(--font-family-heading);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.28px;
  border-radius: 80px;

  transition:
    border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}
@media (--tablet) {
  .product-breadcrumb__content__link {
    padding: 12px;

    font-size: 14px;
  }
}

.product-breadcrumb__content__link--active {
  background-color: black;

  border: 1px solid rgba(0, 0, 0, 0.07);

  color: white;

  box-shadow: 0px 4px 34px 5px rgba(120, 120, 120, 0.09);
}

.product-breadcrumb__content__link:hover:not(
    .product-breadcrumb__content__link--active
  ) {
  color: black;

  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 34px 5px rgba(120, 120, 120, 0.01);
}

.product-breadcrumb--is-sticky .product-breadcrumb__content {
  top: 70px !important;
  width: unset !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.product-breadcrumb__model {
  display: none;
  height: 120px;
}

@media (--tablet) {
  .product-breadcrumb__model {
    height: 80px;
  }

  .product-breadcrumb--is-sticky .product-breadcrumb__content {
    top: calc(52px - var(--tablet-offset)) !important;
  }
}

.product-breadcrumb--is-sticky .product-breadcrumb__model {
  display: block;
}

@media (--tablet) {
  .product-breadcrumb__content {
    width: 100% !important;
    overflow-x: auto;
  }

  .product-breadcrumb--is-sticky .product-breadcrumb__content {
    max-width: calc(
      100vw - var(--page-left-padding) - var(--page-right-padding)
    );
    left: 50% !important;
    right: unset !important;
    transform: translateX(-50%) !important;
  }

  .product-breadcrumb__content::-webkit-scrollbar {
    display: none;
  }
}
