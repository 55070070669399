* + .infos-cards {
  margin-top: 88px;
}

@media (--tablet) {
  * + .infos-cards {
    margin-top: 50px;
  }
}

@media (--mobile) {
  * + .infos-cards {
    margin-top: 27px;
  }
}

.infos-cards__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
@media (--tablet) {
  .infos-cards__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .infos-cards__container {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.infos-cards__container--double {
  grid-template-columns: repeat(2, 1fr);
}

@media (--mobile) {
  .infos-cards__container--double {
    grid-template-columns: 1fr;
  }
}

.infos-cards__container--simple {
  grid-template-columns: 1fr;
}

.infos-cards__card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  color: #776c57;
  text-align: center;
  background: #f6f4f0;
}

.infos-cards__card__icon {
  height: 93px;
  flex-shrink: 0;
  align-self: center;
}

.infos-cards__card__icon svg * {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.infos-cards__card__icon + .infos-cards__card__content {
  margin-top: 21px;
}

.infos-cards__card__content__title h3 {
  font-family: var(--font-family-heading);
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.infos-cards__card__content__description p {
  margin-top: 16px;

  font-family: var(--font-family-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
}
