.timeline > div {
  margin: 79px 0;
}
@media (--mobile) {
  .timeline > div {
    margin: 32px 0;
  }
}

.timeline-date {
  position: relative;
  z-index: 2;

  display: flex;
  justify-content: center;
  padding: 10px;

  background-color: var(--white);
}
.timeline > div:first-child {
  margin-top: 0;
}
.timeline > div:last-child {
  margin-bottom: 0;
}
@media (--mobile) {
  .timeline-date:not(:first-child) {
    padding-top: 32px;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.timeline-date p {
  font-family: var(--font-family);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25;

  color: #776c57;
}
@media (--mobile) {
  .timeline-date p {
    font-size: 18px;
  }
}

.timeline-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  align-items: center;
}
@media (--mobile) {
  .timeline-content {
    grid-template-columns: 1fr;
    grid-gap: 19px;
  }
}

.timeline-content .timeline-content__img {
  order: 1;
}
.timeline-content .timeline-content__text {
  order: 2;
}

.timeline-content--odd .timeline-content__img {
  order: 2;
}
@media (--mobile) {
  .timeline-content--odd .timeline-content__img {
    order: 1;
  }
}
.timeline-content--odd .timeline-content__text {
  order: 1;
}
@media (--mobile) {
  .timeline-content--odd .timeline-content__text {
    order: 2;
  }
}

.timeline-content__text {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 16px 0;
}
@media (--mobile) {
  .timeline-content__text {
    gap: 19px;
  }
}

.timeline-content__text h2 {
  font-family: var(--font-family-heading);

  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.87px;
  text-transform: uppercase;
}
@media (--mobile) {
  .timeline-content__text h2 {
    font-size: 21px;
    letter-spacing: 0.63px;
  }
}
.timeline-content__text p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.75px;
}
@media (--mobile) {
  .timeline-content__text p {
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 1.71;
  }
}

.timeline-content__text__links {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.timeline-content__text__links__title {
  display: flex;

  align-items: center;
  gap: 10px;
}

.timeline-content__text__links__title h3 {
  font-family: var(--font-family-heading);

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;

  color: #776c57;
}

.timeline-content__text__links__links {
  display: flex;
  flex-direction: column;
  gap: 5px;

  border-left: 2px solid #776c57;

  padding-left: 15px;
}

.timeline-content__text__links__links a {
  font-family: var(--font-family);

  width: fit-content;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.497;
  text-decoration-line: none;

  color: #776c57;
}
.timeline-content__text__links__links a:hover {
  text-decoration-line: underline;
}

.timeline-content__text__cta a {
  display: flex;
  flex-direction: row;

  align-items: center;

  width: fit-content;

  gap: 16px;

  font-family: var(--font-family);

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.497;
  text-decoration-line: none;

  color: #776c57;
}
@media (--mobile) {
  .timeline-content__text__cta a {
    font-size: 17px;
    gap: 13px;
  }
}
.timeline-content__text__cta img {
  transition: transform 0.3s ease-in-out;
}
.timeline-content__text__cta a:hover img {
  transform: translateX(5px);
}

.timeline-content > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
