.product-hero-slider__control-button {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  width: 25%;
  height: 100%;
  padding: 0 16px;

  cursor: pointer;
}
@media (pointer: coarse) {
  .product-hero-slider__control-button {
    display: none;
  }
}

.product-hero-slider__control-button:focus {
  outline: none;
}

.product-hero-slider__control-button.product-hero-slider__control-button--previous {
  left: 0;

  justify-content: flex-start;
}
.product-hero-slider__control-button.product-hero-slider__control-button--next {
  right: 0;

  justify-content: flex-end;
}

.product-hero-slider__control-button > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background-color: white;
  border: 1px solid #cbcdd3;
  border-radius: 50%;

  transition: transform 200ms;
}
.product-hero-slider__control-button:hover > span {
  transform: scale(1.1);
}
.product-hero-slider__control-button:active > span {
  transform: scale(1);

  transition: transform 100ms;
}
.product-hero-slider__control-button.product-hero-slider__control-button--previous
  > span {
  padding-right: 3px;
}
.product-hero-slider__control-button.product-hero-slider__control-button--next
  > span {
  padding-left: 3px;
}

.product-hero-slider__control-button:focus > span {
  border: 4px solid var(--focus-color);
}
.js-focus-visible
  .product-hero-slider__control-button:focus:not(.focus-visible)
  > span {
  border: 1px solid #cbcdd3;
}

.product-hero-slider__control-button > span > img {
  --size: 14px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;

  user-select: none;
}
.product-hero-slider__control-button.product-hero-slider__control-button--previous
  > span
  > img {
  transform: rotate(180deg);
}
