.blog-card {
  --color: black;
  --color-rgb: 0, 0, 0;

  position: relative;

  color: var(--color);

  background-color: #f6f4f0;
}

.blog-card__image {
  overflow: hidden;
  width: 100%;

  aspect-ratio: 16 / 9;
}

.blog-card__image img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.blog-card__footer {
  padding: 16px;
}

.blog-card__title {
  display: block;

  font-family: var(--font-family-heading);
  font-size: 18px;
  font-weight: 700;
  line-height: 134.2%;
  color: black;
  text-decoration: none;
}

.blog-card:hover .blog-card__title {
  text-decoration: underline;
}

.blog-card__title::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

* + .blog-card__title {
  margin-top: 8px;
}

.blog-card__introduction {
  display: -webkit-box;
  overflow: hidden;

  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: rgb(0 0 0 / 70%);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

* + .blog-card__introduction {
  margin-top: 8px;
}

.blog-card__date {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: rgb(0 0 0 / 70%);
}

* + .blog-card__date {
  margin-top: 8px;
}
