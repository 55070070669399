.product-hero-slider {
  --card-size: 413px;

  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;

  user-select: none;

  width: var(--card-size);
}
@media (--tablet) {
  .product-hero-slider {
    --card-size: 100%;
  }
}
@media (--mobile) {
  .product-hero-slider {
    --card-size: 100%;
  }
}

@media (max-width: 600px) {
  .product-hero-slider__inner {
    position: relative;
    right: 50%;
    left: 50%;

    width: 100vw;
    margin-right: -50vw;
    margin-left: -50vw;
  }
}

.product-hero-slider--is-sticky {
  top: 170px !important;
}

/** Skeleton screen */
.product-hero-slider__inner > :not(.product-hero-slider__square) {
  visibility: visible;
}

.product-hero-slider__square {
  position: relative;
}

/** Skeleton screen */
.product-hero-slider__square > :not(.product-hero-slider__scroll) {
  visibility: visible;
}

.product-hero-slider__scroll {
  position: relative;

  display: flex;
  width: 100%;
  height: var(--card-size);
  overflow-x: scroll;
  overflow-y: hidden;

  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
@media (max-width: 1200px) {
  .product-hero-slider__scroll {
    height: 50vw;
  }
}
@media (max-width: 600px) {
  .product-hero-slider__scroll {
    height: 320px;

    border: none;
    border-radius: 0;
  }
}
@media (max-width: 500px) {
  .product-hero-slider__scroll {
    height: 278px;
  }
}

.product-hero-slider__scroll::-webkit-scrollbar {
  display: none;
}

.product-hero-slider__list {
  width: 100%;
  height: 100%;

  white-space: nowrap;

  list-style: none;
}
@media (max-width: 600px) {
  .product-hero-slider__list::after {
    display: block;
    width: 0;
    margin-left: 16px;

    content: '\A0';
  }
}

/** Skeleton screen */
.product-hero-slider__list :not(:first-child) {
  display: inline-block;
}

.product-hero-slider__list__item {
  display: inline-block;
  width: 100%;
  height: 100%;

  white-space: nowrap;

  scroll-snap-align: center;
  scroll-snap-stop: always;
}
@media (max-width: 600px) {
  .product-hero-slider__list__item {
    max-width: 100%;
    padding: 0 16px;
    overflow: hidden;
  }
  .product-hero-slider__list__item > * {
    border-radius: 5px;
  }
}

.product-hero-slider__image {
  width: 100%;
  height: 100%;
  padding: 40px;
  object-fit: contain;
}
@media (max-width: 600px) {
  .product-hero-slider__image {
    max-width: 100%;
    padding: 20px;
  }
}

.product-hero-slider__image.product-hero-slider__image--cover {
  min-height: auto;
  padding: 0;
  object-fit: cover;
}

@media (max-width: 600px) {
  .product-hero-slider.product-hero-slider--multiple
    .product-hero-slider__image:not(.product-hero-slider__image--cover) {
    padding-bottom: 32px;
  }
}
