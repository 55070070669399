@media (--mobile) {
  .all-products {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}

.subject + .all-products {
  margin-top: 64px;
}
@media (--mobile) {
  .subject + .all-products {
    margin-top: 40px;
  }
}

.all-products__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
}
@media (--tablet) {
  .all-products__container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (--mobile) {
  .all-products__container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.all-products__loading {
  justify-content: center;

  display: flex;
  margin: 40px;
}
