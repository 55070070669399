.blog-index:first-child,
* + .blog-index {
  margin-top: 64px;
}

@media (--mobile) {
  .blog-index:first-child,
  * + .blog-index {
    margin-top: 40px;
  }
}

.blog-index__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  list-style: none;
}

@media (--tablet) {
  .blog-index__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--mobile) {
  .blog-index__list {
    grid-template-columns: 1fr;
  }
}
