.not-found {
  --margin-top: 40px;

  margin-top: var(--margin-top);
}

.not-found--first {
  --margin-top: 180px;
}

@media (--mobile) {
  .not-found--first {
    --margin-top: 150px;
  }
}

.not-found__button {
  text-align: center;
}

.title + .not-found__button {
  margin-top: 40px;
}

@media (--mobile) {
  .title + .not-found__button {
    margin-top: 16px;
  }
}
